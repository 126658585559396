<template>
  <div>
    <div class="search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="add_time_arr" label="日期">
              <a-range-picker v-model="searchForm.add_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="cash_register_order_no" label="收银订单号">
              <a-input allowClear v-model="searchForm.cash_register_order_no" placeholder="收银软件的订单号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8" class="text-right mt-1">
            <a-button style="margin-right: 10px" @click="resetForm">重置</a-button>
            <a-button type="primary" @click="handlerSearch" html-type="submit">搜索</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <base-table
      ref="orderTableRef"
      id="orderTableID"
      :columnsData="columns"
      rowKey="flow_no"
      :tableData="tableData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
      :getListFunc="initData"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">小程序线下核销记录</h4>
        </div>
      </template>
    </base-table>

  </div>
</template>

<script>
import moment from "moment"
import { rowSpan } from "@/utils/index"
import pageData from "./columns"
import { getOfflineOrderList } from "@/api/shop.js"
export default {
  components: {  },
  data() {
    return {
      ...pageData,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      total: 0,
      tableData: [],
      searchForm: {
        cash_register_order_no: "",
        add_time_arr: [],
        add_time_start: "",
        add_time_end: "",
        page: 1,
        page_count: 20,
      },
      columns: [ 
        {
          title: '收银软件订单号',
          dataIndex: 'cash_register_order_no',
          align: 'center',
          width: "24%",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.cash_register_order_noRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: '类别',
          dataIndex: 'flow_type',
          align: 'center',
          width: 80,
          slots: {
            customRender: 'flow_type'
          },
          slotsType: 'format',
          slotsFunc: (val) => this.formatType(val)
        },
        {
          title: '时间',
          dataIndex: 'verification_time',
          align: 'center',
          width: "18%",
        },
        {
          title: '流水号',
          dataIndex: 'flow_no',
          align: 'center',
          // width: "28%",
        },
        {
          title: '方式',
          dataIndex: 'verification_model',
          align: 'center',
          // width: 100,
          width: "10%",
          slots: {
            customRender: 'verification_model'
          },
          slotsType: 'format',
          slotsFunc: (val) => this.formatMode(val)
        },
        {
          title: '金额',
          dataIndex: 'amount',
          align: 'center',
          // width: 110,
          width: "10%",
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   align: 'center',
        //   width: "15%",
        //   slots: { customRender: 'operation' }
        // },
      ]
    }
  },
  mounted() {

    this.initData()
  },
  methods: {
    moment,
    async initData() {
      if (this.searchForm.add_time_arr.length > 0) {
        this.searchForm.add_time_start = this.searchForm.add_time_arr[0]
        this.searchForm.add_time_end   = this.searchForm.add_time_arr[1]
      }
      else{
        this.searchForm.add_time_start = ''
        this.searchForm.add_time_end   = ''
      }
      const { data, code } = await getOfflineOrderList(this.searchForm)
      if (code === 0) {
        let arr = []
        data.list.map((item) => {
          let obj = item
          item.order_verification_data.map((flow, i) => {
            if(flow.flow_type == 2){
              flow.amount = "-" + Math.abs(flow.amount).toFixed(2)
            }
            obj = { ...obj, ...flow }
            obj.id = obj.cash_register_order_no + i
            arr.push(obj)
          })
        })
        this.tableData = rowSpan(arr, "cash_register_order_no")
        this.total = data.total_count
      }
    },
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    resetForm() {
      this.$refs.searchRef.resetFields()
      this.searchForm.page = 1
      // this.searchForm.cash_register_order_no = ""
      this.initData()
    },

  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>