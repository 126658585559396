
export const flowType = {
  1: '消费',
  2: '退款',
}

export const verifyMode = {
  "-1": '优惠券',
   1: '钱包',
   2: '礼品卡',
   3: '实体卡'
}

export function formatType (val) {
  return flowType[val]
}

export function formatMode (val) {
  return verifyMode[val]
}

export default {
  formatType,
  formatMode
}
